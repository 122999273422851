import React from 'react';
import { useSelector } from 'react-redux';
import { useCountUp } from 'use-count-up';
import { useInView } from "react-intersection-observer";
import { useEffect } from 'react';

function IntroCounter() {
  const dataSupplise = 1187;

  const [ref, inView] = useInView();
  const { value, reset } = useCountUp({
    isCounting: true,
    end: dataSupplise,
    start: 0,
    duration: 2,
  })

  const { supplies } = useSelector((state) => ({
    supplies: state.data.supplies,
  }));

  useEffect(() => {
    if (inView) {
      reset();
    }
  }, [inView, reset]);

  const renderCounter = (value) => {
    // eslint-disable-next-line prefer-const, no-shadow
    const supplies = { claimed: value };

    if (supplies && supplies.claimed) {
      const list = [];
      const claimedList = [...(supplies.claimed.toString() || 0)];
      let key = 0;

      claimedList.forEach((i) => {
        key += 1;

        list.push(
          <div key={key} className="counter">
            <span>{i}</span>
          </div>
        );
      });

      return list;
    }
    return (
      <>
        <div className="counter">0</div>
      </>
    );
  };

  return (
    <div ref={ref} className="home-moca-counter">
      <div className="home-moca-counter-wrapper">{renderCounter(value.toString().padStart(dataSupplise.toString().length, '0'))}</div>
      <div className="text">Mocas have been minted!</div>
    </div>
  );
}

export default IntroCounter;
