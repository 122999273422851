import React from 'react';
import soldoutLogo from '../../assets/images/sold-out.svg';

const IntroSoldout = () => (
  <>
    <img
      src={soldoutLogo}
      alt="All Mocas are minted!"
      className="sold-out-logo"
    />
    <p className="sold-out-info">All Mocas are minted!</p>
  </>
);

export default IntroSoldout;
