import React, { useEffect, useMemo, useState } from 'react';
import TagManager from 'react-gtm-module';
import {
  useLogin,
  useUser,
  useAddress,
  useNetworkMismatch,
  useLogout,
  useNetwork,
  ChainId,
} from '@thirdweb-dev/react';
import { useSelector, useDispatch } from 'react-redux';
import { setAddress, setUser } from '../redux/user/userSlice';
import {
  setEligibility,
  setError,
  setQuota,
  setSupplies,
  setSurveyItems,
} from '../redux/data/dataSlice';
import {
  checkAuthorization,
  getEligibility,
  getSupplies,
  fetchSurveyItems,
} from '../api/fetchData';

const domain = process.env.REACT_APP_THIRDWEB_AUTHDOMAIN;
const desiredChainId =
  domain === 'mocaverse.xyz' ? ChainId.Mainnet : ChainId.Goerli;

const PageWrapper = ({ className, children }) => {
  const [isLoginAttempted, setIsLoginAttempted] = useState(false);
  const networknNotMatched = useNetworkMismatch();
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => ({
    currentUser: state.user?.user,
  }));
  const { login } = useLogin();
  const { logout } = useLogout();
  const [, switchNetwork] = useNetwork();
  const user = useUser();
  const address = useAddress();

  const getEl = async () => {
    const quotas = await checkAuthorization();
    dispatch(setQuota(quotas));

    const el = await getEligibility();
    if (el.quotas) {
      dispatch(setEligibility(el));
    } else if (el.errors) {
      if (el.errors[0].message === 'User has not completed the survey') {
        console.log('navigate to moca');
      } else {
        dispatch(setError(el.errors[0].message));
      }
    }
  };

  const getData = async () => {
    const res = await getSupplies();
    dispatch(setSupplies(res));
    const data = await fetchSurveyItems();
    if (data.objects) {
      dispatch(setSurveyItems(data.objects));
    } else {
      dispatch(setError('Something went wrong, try again later'));
    }
  };

  const handleProcess = () => {
    // need to log out after switching another method
    if (
      address &&
      user.user &&
      user.user.address &&
      address !== user.user.address
    ) {
      logout()
        .then(() => {
          dispatch(setUser(null));
          setIsLoginAttempted(false);
        })
        .catch((err) => {
          console.log('logout error', err);
          // further state handling re UI
        });
      return;
    }
    // disconnect
    if (!address && user?.user && currentUser && isLoginAttempted) {
      dispatch(setQuota(null));
      dispatch(setEligibility(null));
      dispatch(setSupplies(null));
      dispatch(setSurveyItems(null));
    }
    // logged
    if (!address && !user?.user && currentUser && isLoginAttempted) {
      dispatch(setUser(null));
      setIsLoginAttempted(false);
      return;
    }
    if (address && !currentUser && user.user && !user.isLoading) {
      dispatch(setUser(user));
      dispatch(setAddress(user.address));
      getEl();
      getData();
    } else if (address && !user.isLoading && !isLoginAttempted) {
      setIsLoginAttempted(true);
      login().catch((err) => {
        console.log('login error', err);
        // further state handling re UI
        dispatch(setQuota(null));
        dispatch(setEligibility(null));
        dispatch(setSupplies(null));
        dispatch(setSurveyItems(null));
        dispatch(setUser(null));
        setIsLoginAttempted(false);
      });
    }
  };

  useEffect(() => {
    if (networknNotMatched && address) {
      switchNetwork(desiredChainId)
        .then(() => {
          handleProcess(user, address);
        })
        .catch((e) => console.log('switching network error', e));
      return;
    }
    handleProcess(user, address);
  }, [user?.user, user?.isLoggedIn, address]);

  const tagManagerArgs = useMemo(
    () => ({
      dataLayer: {
        userId: address || '',
      },
      dataLayerName: 'PageDataLayer',
    }),
    [address]
  );

  useEffect(() => {
    TagManager.dataLayer(tagManagerArgs);
  }, [tagManagerArgs]);

  return <main className={className}>{children}</main>;
};

export default React.memo(PageWrapper);
