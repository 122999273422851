import { configureStore } from '@reduxjs/toolkit';
import dataSlice from './data/dataSlice';
import userSlice from './user/userSlice';
import audioSlice from './audio/audioSlice';

export const store = configureStore({
  reducer: {
    audio: audioSlice,
    user: userSlice,
    data: dataSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
