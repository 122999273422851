/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from 'react';
import Discord from '../../assets/images/discord.svg';
import Twitter from '../../assets/images/twitter.svg';
import Instagram from '../../assets/images/instagram.svg';
import copy from '../../assets/images/copy.svg';

const CompletionResult = ({ surveySelectedItems, eligibility }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async (text) => {
    await navigator.clipboard.writeText(text);
    setCopied(true);
  };

  if (!eligibility) return <div className="personality-results" />;

  const des = eligibility.hint;

  const renderSelectedItems = useMemo(() => {
    // or using progress.itemCase
    const content = [];
    if (!surveySelectedItems) return content;
    const items = Object.values(surveySelectedItems);
    items.forEach((item) => {
      content.push(
        <span
          key={item.id}
          className={`personality-trait personality-trait--${item.type}`}
        />
      );
    });
    return content;
  }, [surveySelectedItems]);

  return (
    <div className="personality-results">
      <h2 className="header">Your Moca Personality</h2>
      <div className="personality-traits-wrapper">{renderSelectedItems}</div>
      <div className="personality-desc">
        <h2 className="personality-header">
          {' '}
          {/* Wonderful! You are : adj1, adj2, adj3 ! */}
        </h2>
        <p>{des}</p>
      </div>
      <div className="share">
        <a
          className="discord"
          href="https://discord.com/invite/MocaverseNFT"
          target="_blank"
          title="Discord"
          rel="noreferrer"
        >
          <img src={Discord} alt="Discord" />
        </a>
        <a
          href="https://twitter.com/MocaverseNFT"
          target="_blank"
          title="Twitter"
          rel="noreferrer"
          className="twitter"
        >
          <img src={Twitter} alt="Twitter" />
        </a>
        <button className="copy" onClick={() => handleCopy('')}>
          <img src={copy} alt="Copy" /> Copy Content
        </button>
      </div>
    </div>
  );
};

export default CompletionResult;
