import React, { useEffect } from 'react';
import {
  Header, Intro, Socials, Story
} from '../components';
import PageWrapper from '../components/PageWrapper';

const Home = () => {
  useEffect(() => {
    document.body.classList.add('home');
  });

  return (
    <>
      <div className="bg" />
      <div className="stars" />
      <PageWrapper>
        <Header />
        <Intro />
        <Story/>
        <Socials />
      </PageWrapper>
    </>
  );
};

export default Home;
