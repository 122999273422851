import React, { useState } from 'react';
import NoteIcon from '../../assets/images/note.svg';

const IntroNote = () => {
  const [show, setShow] = useState(false);

  const cls = show ? 'open' : '';

  const handleShow = () => {
    setShow(!show);
  };

  return (
    <div className={`intro-note ${cls}`}>
      <button className="btn-intro-note" onClick={handleShow}>
        <span className="btn-intro-note-icon">
          <img src={NoteIcon} aria-label="note" title="note" alt="note" />
        </span>
        <span>Note for delegated wallet holders</span>
      </button>
      <div className="intro-note-content">
        <span className="intro-note-arrow" />
        <p>
          Only users who have previously registered their delegated wallet
          request through the Mocaverse Discord will be able to mint through
          their Warm.xyz or Delegate.cash delegated wallet. Please connect and
          mint using your delegated wallet. If you have any issues, please reach
          out and contact us through Discord. Thank you!
        </p>
      </div>
    </div>
  );
};

export default IntroNote;
