import React from 'react';
import FlipNumbers from 'react-flip-numbers';
import { useCountdown } from '../../hooks/useCountDown';

const Digit = ({ digit }) => (
  <div className="count-down_item">
    <FlipNumbers play width={14} height={24} numbers={`${digit}`} />
  </div>
);

function IntroCountdown() {
  const { hours, minutes, seconds } = useCountdown();

  return (
    <div className="home-countdown">
      <div className="timer">
        <div className="count-down">
          <Digit digit={hours} />
          <Digit digit={minutes} />
          <Digit digit={seconds} />
        </div>
      </div>
    </div>
  );
}

export default IntroCountdown;
