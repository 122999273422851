/* eslint-disable no-underscore-dangle */
import React from 'react';
import { useDispatch } from 'react-redux';

import MocaWorkerBlue from '../../assets/images/worker-moca-blue.svg';
import MocaWorkerGreen from '../../assets/images/worker-moca-green.svg';
import MocaWorkerYellow from '../../assets/images/worker-moca-yellow.svg';
import MocaWorkerPink from '../../assets/images/worker-moca-pink.svg';

import { setError, setRealms } from '../../redux/data/dataSlice';
import { getRealms, submitRealms } from '../../api/fetchData';

import {
  LearnAnimation,
  InvestAnimation,
  PlayAnimation,
  DoGoodAnimation,
  LearnTextAnimation,
  InvestTextAnimation,
  PlayTextAnimation,
  DoGoodTextAnimation,
} from '../realms/index';

// const LearnAnimation = React.lazy(() => import('../realms/LearnRealm'));
// const InvestAnimation = React.lazy(() => import('../realms/InvestRealm'));
// const PlayAnimation = React.lazy(() => import('../realms/PlayRealm'));
// const DoGoodAnimation = React.lazy(() => import('../realms/DoGoodRealm'));

// const LearnTextAnimation = React.lazy(() => import('../realms/LearnRealmText'));
// const InvestTextAnimation = React.lazy(() =>
//   import('../realms/InvestRealmText')
// );
// const PlayTextAnimation = React.lazy(() => import('../realms/PlayRealmText'));
// const DoGoodTextAnimation = React.lazy(() =>
//   import('../realms/DoGoodRealmText')
// );

const CompletionRealms = ({ mint, transaction, error, realms }) => {
  // Access all of the progress from the context to finalise process
  const dispatch = useDispatch();

  const fetchRealms = async () => {
    try {
      const data = await getRealms();
      if (data.errors) {
        dispatch(setError(data.errors[0].message));
      } else {
        dispatch(setRealms(data));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const sendToRealm = async (realm) => {
    try {
      const realmId = realms.realms.find((x) => x.name === realm).id;
      const tokenId = parseInt(transaction[0].id._hex, 16);
      if (realmId && tokenId) {
        const data = await submitRealms(realmId, tokenId);
        if (data.errors) {
          dispatch(setError(data.errors[0].message));
        } else if (data.message === 'Success') {
          fetchRealms();
        }
      } else {
        console.log('missing transaction or realms', { transaction, realms });
        // dispatch(setError('Something went wrong, try again later'));
      }
    } catch (e) {
      console.log(e);
      // dispatch(setError('Something went wrong, try again later'));
    }
  };

  if (!mint || !realms || !transaction) {
    return <div className="pick-a-realm" />;
  }

  const quantity = mint ? parseInt(mint.payload.quantity.hex, 16) : 0;
  const totalRealms = {
    learn:
      realms.realms.find((x) => x.name === 'learn')?.total_sent_to_realm || 0,
    grow:
      realms.realms.find((x) => x.name === 'grow')?.total_sent_to_realm || 0,
    play:
      realms.realms.find((x) => x.name === 'play')?.total_sent_to_realm || 0,
    doGood:
      realms.realms.find((x) => x.name === 'do good')?.total_sent_to_realm || 0,
  };

  return (
    <>
      <div className="pick-a-realm">
        <p>Pick One realm below for your mocas to travel to!</p>
        <div className="moca-token">
          <span>X {quantity}</span>
        </div>
        {error && (
          <div className="errorWrap">
            <p className="text errorLabel">{error}</p>
          </div>
        )}
      </div>
      <div className="realm__row">
        <div className="realm__learn">
          <div className="realm__content">
            <LearnTextAnimation />
            <LearnAnimation />
            <div className="send-moca">
              <div className="mocas">
                <img
                  src={MocaWorkerPink}
                  alt="Learn Realm"
                  className="mocaWorker--pink"
                />
                <div className="moca-text count">{totalRealms.learn}</div>
              </div>
              <button
                className="moca-text txt btn"
                onClick={() => sendToRealm('learn')}
              >
                Travel here
              </button>
            </div>
          </div>
        </div>
        <div className="realm__build">
          <div className="realm__content">
            <InvestTextAnimation />
            <InvestAnimation />
            <div className="send-moca">
              <div className="mocas">
                <img
                  src={MocaWorkerBlue}
                  alt="Invest Realm"
                  className="mocaWorker--blue"
                />
                <div className="moca-text count">{totalRealms.grow}</div>
              </div>
              <button
                className="moca-text txt btn"
                onClick={() => sendToRealm('grow')}
              >
                Travel here
              </button>
            </div>
          </div>
        </div>
        <div className="realm__play">
          <div className="realm__content">
            <PlayTextAnimation />
            <PlayAnimation />
            <div className="send-moca">
              <div className="mocas">
                <img
                  src={MocaWorkerYellow}
                  alt="Play Realm"
                  className="mocaWorker--yellow"
                />
                <div className="moca-text count">{totalRealms.play}</div>
              </div>
              <button
                className="moca-text txt btn"
                onClick={() => sendToRealm('play')}
              >
                Travel here
              </button>
            </div>
          </div>
        </div>
        <div className="realm__dogood">
          <div className="realm__content">
            <DoGoodTextAnimation />
            <DoGoodAnimation />
            <div className="send-moca">
              <div className="mocas">
                <img
                  src={MocaWorkerGreen}
                  alt="Do Good Realm"
                  className="mocaWorker--green"
                />
                <div className="moca-text count">{totalRealms.doGood}</div>
              </div>
              <button
                className="moca-text txt btn"
                onClick={() => sendToRealm('do good')}
              >
                Travel here
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompletionRealms;
