import React from 'react';
import IntroCountdown from './intro/IntroCountdown';
import IntroSocials from './intro/IntroSocials';
import IntroCounter from './intro/IntroCounter';
import PlayerRealms from './PlayerRealms';
import IntroSection from './intro/IntroSection';
import IntroNote from './intro/IntroNote';
import IntroSoldout from './intro/IntroSoldout';
import IntroComingSoon from './intro/IntroComingSoon';

const Intro = ({ isMint = false }) => {
  if (isMint)
    return (
      <IntroComingSoon
        socials={<IntroSocials />}
        playerRealms={<PlayerRealms />}
      />
    );
  return (
    <IntroSection
    note={<IntroNote />}
    countdown={<IntroCountdown />}
    socials={<IntroSocials />}
    counter={<IntroCounter />}
    playerRealms={<PlayerRealms />}
    soldOut={<IntroSoldout />}
  />
  );
}

export default Intro;
