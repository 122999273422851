import React from 'react';
import {
  useMetamask,
  useCoinbaseWallet,
  useWalletConnect,
} from '@thirdweb-dev/react';
import Metamask from '../assets/images/metamask.png';
import CoinBase from '../assets/images/coinbase.png';
import WalletConect from '../assets/images/wallet-connect.png';
import ModalWrapper from './common/ModalWrapper';

const Modal = ({ toggleModal }) => {
  const connectMeta = useMetamask();
  const connectCoinbase = useCoinbaseWallet();
  const connectWallet = useWalletConnect();

  const handleMeta = () => {
    window.localStorage.setItem('connectName', 'Metamask');
    connectMeta();
  };
  const handleCoinbase = () => {
    window.localStorage.setItem('connectName', 'Coinbase');
    connectCoinbase();
  };
  const handleWallet = () => {
    window.localStorage.setItem('connectName', 'Wallet Connect');
    connectWallet();
  };

  return (
    <ModalWrapper className="modal-wappar" onClose={() => toggleModal()}>
      <div className="modal-content">
        <h2>CONNECT WALLET</h2>
        <button type="button" onClick={handleMeta} id="metamask">
          <img className="with-metamask" src={Metamask} alt="close" />
        </button>
        <button type="button" onClick={handleCoinbase} id="coinbase">
          <img className="with-coinbase" src={CoinBase} alt="close" />
        </button>
        <button type="button" onClick={handleWallet} id="walletconnect">
          <img className="with-walletconnect" src={WalletConect} alt="close" />
        </button>
      </div>
    </ModalWrapper>
  );
};

export default Modal;
