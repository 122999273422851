import React from 'react';
import CompletionWrapper from '../components/completion/CompletionWrapper';
import CompletionContent from '../components/completion/CompletionContent';

const Completion = () => (
  <CompletionWrapper>
    <CompletionContent />
  </CompletionWrapper>
);

export default Completion;
