import { useState, useEffect } from "react";
import { formatNumber } from "../utils/textHelper";

const targetDate = process.env.REACT_APP_COUNTDOWN_DATE;

export const useCountdown = () => {
  const countDownDate = new Date(targetDate).getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);
  

  // const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  // const hours = Math.floor(
  //   (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  // );
  const hours = Math.floor(
    countDown/ (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  if(countDown < 0) {
    return {hours: 'hh', minutes: 'mm', seconds: 'ss'}
  }

  return { hours: hours? formatNumber(hours) :'hh', minutes: minutes? formatNumber(minutes):'mm', seconds: seconds? formatNumber(seconds):'ss'};
};

