import React from 'react';
import { InvestAnimation, InvestTextAnimation } from './index';

// const InvestAnimation = React.lazy(() => import('./InvestRealm'));
// const InvestTextAnimation = React.lazy(() => import('./InvestRealmText'));

const text =
  'Support the next generation of dreamers and builders and connect with like-minded believers in Web3';

const InvestRealmItem = ({ isSlider = false }) => {
  if (isSlider) {
    return (
      <div className="realms-slider-item">
        <InvestAnimation />
        <div className="realm-info invest">
          <InvestTextAnimation />
          {text}
        </div>
      </div>
    );
  }

  return (
    <div className="realm__build">
      <div className="realm__content">
        <InvestTextAnimation />
        <InvestAnimation />
        <div className="realm__info">{text}</div>
      </div>
    </div>
  );
};

export default InvestRealmItem;
