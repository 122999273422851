import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import FaqTextAnimation from './FaqTitle';

// const FaqTextAnimation = React.lazy(() => import('./FaqTitle'));

const FaqContent = () => (
  <div className="faq">
    <div className="logo-block">
      <FaqTextAnimation />
    </div>
    <div className="faq-block">
      <div className="faq-scroll">
        <Accordion>
          <Accordion.Item eventKey="0" className="questions">
            <Accordion.Header className="btn-wrap">
              What is Mocaverse?
              <span className="arrow" />
            </Accordion.Header>
            <Accordion.Body>
              <div className="answers">
                <p>
                  Mocaverse is the membership NFT collection for Animoca Brands’
                  extraordinary family of companies, projects, investments,
                  shareholders, and partners.
                </p>
                <p>
                  This collection will represent our values surrounding Web3 and
                  digital property rights, as well as our one-of-a-kind
                  ecosystem.
                </p>
                <p>
                  We wish to express our deepest appreciation for the immense
                  support of our friends and family by inviting them to this
                  exclusive membership experience. We simply would not be where
                  we are without them.
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1" className="questions">
            <Accordion.Header className="btn-wrap">
              Who are the Mocas?
              <span className="arrow" />
            </Accordion.Header>
            <Accordion.Body>
              <div className="answers">
                <p>
                  The Mocas are a lively community of beings that live in the
                  Mocaverse. There are 5 Moca tribes: Dreamers, Builders,
                  Angels, Connectors, and Neo-Capitalists.
                </p>
                <p>
                  While the Mocas may have their own special abilities, they are
                  most empowered when they work together. The real magic happens
                  when everyone unites for a greater purpose.
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2" className="questions">
            <Accordion.Header className="btn-wrap">
              What are the utilities of Mocaverse?
              <span className="arrow" />
            </Accordion.Header>
            <Accordion.Body>
              <div className="answers">
                <p>
                  Holders will be able to Learn, Play, Do Good, and Build
                  together. Utilities will be co-created by our communities,
                  enjoyed by our communities, and powered by Animoca Brands.
                </p>
                <p>
                  Our utility offerings will be diverse, with just a few
                  examples below:
                </p>
                <p>
                  Learn: Masterclasses and AMAs with industry leaders,
                  educational research insights
                </p>
                <p>
                  Play: Exclusive game passes, access to unique in-game assets
                  and airdrops
                </p>
                <p>
                  Do Good: Opportunities to make meaningful contributions
                  towards social causes
                </p>
                <p>
                  Build: Access to resources and tools for builders in the Web3
                  space
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3" className="questions">
            <Accordion.Header className="btn-wrap">
              Who is eligible to mint?
              <span className="arrow" />
            </Accordion.Header>
            <Accordion.Body>
              <div className="answers">
                <p>
                  Mocaverse NFTs are reserved exclusively for friends and family
                  of Animoca Brands, including the following eligible parties:
                </p>
                <ol>
                  <li>Animoca Brands’ institutional and retail investors</li>
                  <li>Employees of Animoca Brands and its subsidiaries</li>
                  <li>
                    Management teams of companies in Animoca Brands’ Web3
                    investment portfolio
                  </li>
                  <li>Partners of Animoca Brands</li>
                  <li>Mocalist members</li>
                </ol>
                <p>
                  All eligible parties will receive a free mint quota and a paid
                  mint quota. Parties in categories 1-4 should already have
                  received an email to submit their wallet address for the
                  minting event. Learn more about category 5 eligibility in our
                  <a
                    href="https://medium.com/mocaverse/mocaverse-announces-full-eligibility-criteria-and-mocalist-registration-event-27fcbcdb77"
                    title="Medium article"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Medium article
                  </a>
                  .
                </p>
                <p>
                  If you are an eligible party but have not received an email,
                  please contact{' '}
                  <a
                    href="mailto:mocaverse@animocabrands.com"
                    title="mocaverse@animocabrands.com"
                  >
                    mocaverse@animocabrands.com
                  </a>
                  .
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4" className="questions">
            <Accordion.Header className="btn-wrap">
              When is the mint date, and what is the collection size and mint
              price?
              <span className="arrow" />
            </Accordion.Header>
            <Accordion.Body>
              <div className="answers">
                <p>
                  Mocaverse will be released in Q1 of 2023! Mocaverse comprises
                  8,888 NFTs of characters called Mocas, each belonging to a
                  tribe. Eligible parties will receive a free mint quota and a
                  paid mint quota.
                </p>
                <p>The mint price will be 0.138 ETH per paid NFT mint.</p>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5" className="questions">
            <Accordion.Header className="btn-wrap">
              What tokens will be accepted for payment?
              <span className="arrow" />
            </Accordion.Header>
            <Accordion.Body>
              <div className="answers">
                <p>
                  Eligible parties may mint their NFTs using the following
                  tokens on Ethereum Mainnet (ERC-20):
                </p>
                <ul>
                  <li>$ETH</li>
                  <li>$USDC</li>
                  <li>$APE</li>
                  <li>$SAND</li>
                  <li>$ASTRAFER</li>
                  <li>$BONDLY</li>
                  <li>$GMEE</li>
                  <li>$REVV</li>
                  <li>$TOWER</li>
                  <li>$PRIMATE</li>
                  <li>$DOSE</li>
                  <li>$PROS</li>
                  <li>$QUIDD</li>
                </ul>
                <p>
                  Kindly ensure there will also be sufficient ETH in the wallet
                  for gas fees.
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6" className="questions">
            <Accordion.Header className="btn-wrap">
              May I mint with a delegated wallet instead of my hardware wallet?
              <span className="arrow" />
            </Accordion.Header>
            <Accordion.Body>
              <div className="answers">
                <p>
                  Only users who have previously registered their delegated
                  wallet request through the Mocaverse Discord will be able to
                  mint through their Warm.xyz or Delegate.cash delegated wallet.
                  Please connect and mint using your delegated wallet. If you
                  have any issues, please reach out and contact us through
                  Discord. Thank you!
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7" className="questions">
            <Accordion.Header className="btn-wrap">
              What is the allocation of Mocaverse NFTs?
              <span className="arrow" />
            </Accordion.Header>
            <Accordion.Body>
              <div className="answers">
                <p>
                  The approximate allocation of Mocaverse NFTs will be as
                  follows:
                </p>
                <p>
                  Company mint (company reserve and DAO): 888 NFTs
                  <br />
                  Public mint (Investors, Employees, Portfolio Companies,
                  Partners, Mocalist Community): 8,000 NFTs
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="8" className="questions">
            <Accordion.Header className="btn-wrap">
              Will minting be done in phases?
              <span className="arrow" />
            </Accordion.Header>
            <Accordion.Body>
              <div className="answers">
                <p>
                  A company mint will take place before the public mint. The
                  public mint will then open the following day for all
                  allowlisted parties in categories 1-5 (Investors, Employees,
                  Portfolio Companies, Partners, Mocalist Community).
                </p>
                <p>
                  The NFT supply will be available for eligible parties to mint
                  their free and paid NFT mints on a first-come-first-serve
                  basis. Minting will last for 24 hours.
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="9" className="questions">
            <Accordion.Header className="btn-wrap">
              What can I do with a Realm Ticket NFT? How can I check if I won a
              Mocalist spot?
              <span className="arrow" />
            </Accordion.Header>
            <Accordion.Body>
              <div className="answers">
                <p>
                  Eligible parties received their Realm Ticket NFT(s) via an
                  airdrop based on their holdings of Animoca Brands ecosystem
                  tokens and NFTs. The Realm Ticket NFT(s) was airdropped
                  directly to eligible parties’ cryptocurrency wallets. Parties
                  who were not eligible for the Realm Ticket NFT airdrop may
                  obtain the Realm Ticket NFTs from secondary markets.
                </p>
                <p>
                  Each Realm Ticket NFT counts as an individual entry for an
                  opportunity to win a spot on the Mocalist for minting
                  eligibility.
                </p>
                <p>
                  On 2 February, there will be a snapshot of Realm Ticket NFT
                  holders. Mocalist Members will randomly be selected from this
                  pool of Realm Ticket NFT holders. Results will be posted on
                  <a
                    href="https://twitter.com/MocaverseNFT"
                    target="_blank"
                    title="Twitter"
                    rel="noreferrer"
                  >
                    Twitter
                  </a>{' '}
                  and{' '}
                  <a
                    href="https://discord.com/invite/MocaverseNFT"
                    target="_blank"
                    title="Discord"
                    rel="noreferrer"
                  >
                    Discord
                  </a>
                  , so be sure to join us there!
                </p>
                <p>
                  Mocalist Members will be added to the minting allowlist, with
                  eligibility for 1 free mint and 1 paid mint.
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="10" className="questions">
            <Accordion.Header className="btn-wrap">
              By minting this NFT, what licence terms do I agree to?
              <span className="arrow" />
            </Accordion.Header>
            <Accordion.Body>
              <div className="answers">
                <p>
                  By minting a Mocaverse NFT, users will enter a Licence
                  Agreement that grants them a licence for limited commercial
                  use of their NFT! As outlined in the Licence Agreement,
                  holders would be entitled to a “non-exclusive,
                  non-transferrable, sublicensable, universe-wide, royalty-free
                  licence to use the Licensed NFT Artwork in any and all formats
                  and media.” This includes the ability for commercialisation of
                  the NFT.
                </p>
                <p>
                  To learn more about the licence terms, we invite our Mocas to
                  view the Licence Agreement contents at our{' '}
                  <a
                    href="http://github.com"
                    target="_blank"
                    title="GitHub repository"
                    rel="noreferrer"
                  >
                    GitHub repository
                  </a>
                  ! We strive for creator rights and transparency.
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  </div>
);

export default FaqContent;
