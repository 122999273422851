import React from 'react';
import TribeImage1 from '../../assets/images/story/tribe-1.svg';
import TribeImage2 from '../../assets/images/story/tribe-2.svg';
import TribeImage3 from '../../assets/images/story/tribe-3.svg';
import TribeImage4 from '../../assets/images/story/tribe-4.svg';
import TribeImage5 from '../../assets/images/story/tribe-5.svg';
import Twitter from '../../assets/images/twitter-yellow.svg';

const StoryTribe = () => (
  <div className="story-tribe">
    <ul className="story-tribe-list">
      <li className="story-tribe-item">
        <div className="story-tribe-box">
          <div className="story-tribe-image">
            <img src={TribeImage1} alt="Twitter" />
          </div>
          <p className="story-tribe-subtitle">Dreamer</p>
          <h2 className="story-tribe-name">Jaeson Ma</h2>
          <div className="story-tribe-socials">
            <a
              href="https://twitter.com/jaesonma?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
              target="_blank"
              rel="noreferrer"
              title="Twitter"
            >
              <img src={Twitter} alt="Twitter" />
            </a>
          </div>
        </div>
      </li>
      <li className="story-tribe-item">
        <div className="story-tribe-box">
          <div className="story-tribe-image">
            <img src={TribeImage2} alt="Twitter" />
          </div>
          <p className="story-tribe-subtitle">Builder</p>
          <h2 className="story-tribe-name">Sébastien Borget</h2>
          <div className="story-tribe-socials">
            <a
              href="https://twitter.com/borgetsebastien"
              target="_blank"
              rel="noreferrer"
              title="Twitter"
            >
              <img src={Twitter} alt="Twitter" />
            </a>
          </div>
        </div>
      </li>
      <li className="story-tribe-item">
        <div className="story-tribe-box">
          <div className="story-tribe-image">
            <img src={TribeImage3} alt="Twitter" />
          </div>
          <p className="story-tribe-subtitle">Angel</p>
          <h2 className="story-tribe-name">Whaleshark</h2>
          <div className="story-tribe-socials">
            <a
              href="https://twitter.com/WhaleSharkETH"
              target="_blank"
              rel="noreferrer"
              title="Twitter"
            >
              <img src={Twitter} alt="Twitter" />
            </a>
          </div>
        </div>
      </li>
      <li className="story-tribe-item">
        <div className="story-tribe-box">
          <div className="story-tribe-image">
            <img src={TribeImage4} alt="Twitter" />
          </div>
          <p className="story-tribe-subtitle">Connector</p>
          <h2 className="story-tribe-name">Brian D. Evans</h2>
          <div className="story-tribe-socials">
            <a
              href="https://twitter.com/BrianDEvans"
              target="_blank"
              rel="noreferrer"
              title="Twitter"
            >
              <img src={Twitter} alt="Twitter" />
            </a>
          </div>
        </div>
      </li>
      <li className="story-tribe-item">
        <div className="story-tribe-box">
          <div className="story-tribe-image">
            <img src={TribeImage5} alt="Twitter" />
          </div>
          <p className="story-tribe-subtitle">Neo-Capitalist</p>
          <h2 className="story-tribe-name">Murtaza Akbar</h2>
          <div className="story-tribe-socials">
            <a
              href="https://twitter.com/LCVentures"
              target="_blank"
              rel="noreferrer"
              title="Twitter"
            >
              <img src={Twitter} alt="Twitter" />
            </a>
          </div>
        </div>
      </li>
    </ul>
  </div>
);

export default StoryTribe;
