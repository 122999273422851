import React from 'react';
import { LearnAnimation, LearnTextAnimation } from './index';

// const LearnAnimation = React.lazy(() => import('./LearnRealm'));
// const LearnTextAnimation = React.lazy(() => import('./LearnRealmText'));

const text =
  'Idea exchange, learning, discovery, and non-linear growth in a limitless space for uniting Web3 insights';

const LearnRealmItem = ({ isSlider = false }) => {
  if (isSlider) {
    return (
      <div className="realms-slider-item">
        <LearnAnimation />
        <div className="realm-info learn">
          <LearnTextAnimation />
          {text}
        </div>
      </div>
    );
  }

  return (
    <div className="realm__learn">
      <div className="realm__content">
        <LearnTextAnimation />
        <LearnAnimation />
        <div className="realm__info">{text}</div>
      </div>
    </div>
  );
};

export default LearnRealmItem;
