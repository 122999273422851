import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie-player';
// import animationData from '../../assets/lottie/PlayRealm.json';

const PlayAnimation = () => {
  const [animationData, setAnimationData] = useState();

  useEffect(() => {
    import('../../assets/lottie/PlayRealm.json').then(setAnimationData);
  }, []);

  if (!animationData) return <div className="realm" />;
  return (
    <Lottie
      animationData={animationData}
      background="transparent"
      play
      speed={1}
      loop={false}
      className="realm"
    />
  );
};

export default React.memo(PlayAnimation);
