/* eslint-disable no-unused-vars */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMintingProgress } from '../../context/stateManager';
import CompletionConfirmation from './CompletionConfirmation';
import CompletionRealms from './CompletionRealms';
import CompletionResult from './CompletionResult';

const CompletionContent = () => {
  const navigate = useNavigate();
  const {
    surveyItems,
    surveySelectedItems,
    eligibility,
    mint,
    transaction,
    error,
    realms,
  } = useSelector((state) => ({
    surveyItems: state.data.surveyItems,
    surveySelectedItems: state.data.surveySelectedItems,
    eligibility: state.data.eligibility,
    mint: state.data.mint,
    transaction: state.data.transaction,
    realms: state.data.realms,
    error: state.data.error,
  }));

  const { progress } = useMintingProgress();

  if (
    !eligibility ||
    !mint ||
    !transaction ||
    !surveyItems ||
    !realms ||
    // !surveySelectedItems ||
    progress.step !== 3
  ) {
    navigate('/mint');
  }

  return (
    <>
      <CompletionConfirmation
        eligibility={eligibility}
        mint={mint}
        transaction={transaction}
      />
      <CompletionRealms
        mint={mint}
        transaction={transaction}
        error={error}
        realms={realms}
      />
      <CompletionResult
        surveySelectedItems={surveySelectedItems}
        eligibility={eligibility}
      />
    </>
  );
};

export default CompletionContent;
