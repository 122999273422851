import React from 'react';
import { formatDotDot } from '../../utils/textHelper';
import Placeholder from '../../assets/images/results-placeholder.gif';

const domain = process.env.REACT_APP_THIRDWEB_AUTHDOMAIN;
const ethLink =
  domain === 'mocaverse.xyz'
    ? 'https://etherscan.io/tx/'
    : 'https://goerli.etherscan.io/tx/';

const CompletionConfirmation = ({ eligibility, mint, transaction }) => {
  if (!transaction || !mint || !eligibility)
    return <div className="completion-mint-confirmed" />;

  const { transactionHash } = transaction[0].receipt;

  const formatTransactionHash = formatDotDot(transactionHash, '...', 8, 6);

  const quantity = parseInt(mint.payload.quantity.hex, 16) || 0;
  const remainingFree = eligibility.quotas.remaining.free || 0;
  const remainingPaid = eligibility.quotas.remaining.paid || 0;

  return (
    <div className="completion-mint-confirmed">
      <div className="completion-image">
        <img src={Placeholder} alt="" />
      </div>
      <div className="content">
        <h2>Minted!</h2>
        <p>You minted {quantity} Moca(s)</p>
        <div className="contract">
          <a
            href={`${ethLink}${transactionHash}`}
            title="ETH"
            target="_blank"
            className="your-wallet-address "
            rel="noreferrer"
          >
            <span> {formatTransactionHash} </span>
            <span className="moca-icon-arrow" />
          </a>
        </div>
        <p className="sub">
          You have {remainingFree} Free Mint(s) and <br />
          {remainingPaid} Paid Mint(s) remaining
        </p>
      </div>
    </div>
  );
};

export default CompletionConfirmation;
