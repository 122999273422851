import React from 'react';
import Discord from '../../assets/images/discord-black.svg';
import Twitter from '../../assets/images/twitter-black.svg';
import OpenSea from '../../assets/images/opensea-black.svg';
import Etherscan from '../../assets/images/etherscan-black.svg';

const HeaderSocials = () => (
  <div className="social-btn">
    <ul>
      <li>
        <a
          href="http://discord.gg/MocaverseNFT"
          className="socials"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Discord} alt="Discord" />
        </a>
      </li>
      <li>
        <a
          href="https://twitter.com/MocaverseNFT"
          className="socials"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Twitter} alt="Twitter" />
        </a>
      </li>
      <li>
        <a
          href="https://opensea.io/0xda4966870a9b4ce849c2011a007a896b7f212b70"
          className="socials"
          target="_blank"
          rel="noreferrer"
        >
          <img src={OpenSea} alt="OpenSea" />
        </a>
      </li>
      <li>
        <a
          href="https://etherscan.io/address/0x85d0a718621906ffd85f88701283230cef22894e"
          className="socials"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Etherscan} alt="Etherscan" />
        </a>
      </li>
    </ul>
  </div>
);

export default React.memo(HeaderSocials);
