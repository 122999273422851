import React from 'react';
import './assets/scss/style.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ChainId, ThirdwebProvider } from '@thirdweb-dev/react';
import { useSelector } from 'react-redux';

import Home from './pages/Home';
import Error from './pages/404';

import Completion from './pages/Completion';
import Mint from './pages/Mint';
import Test from './pages/Test';
import { MintingProgressProvider } from './context/stateManager';

const authUrl = process.env.REACT_APP_THRIDWEB_API_URL;
const domain = process.env.REACT_APP_THIRDWEB_AUTHDOMAIN;
const siteUrl = process.env.REACT_APP_SITE_URL || 'https://www.mocaverse.xyz';
const desiredChainId =
  domain === 'mocaverse.xyz' ? ChainId.Mainnet : ChainId.Goerli;

const App = () => {
 
  return (
    <ThirdwebProvider
      desiredChainId={desiredChainId}
      authConfig={{
        authUrl: `${authUrl}/auth`,
        domain: `${domain}`,
        loginRedirect: `${siteUrl}`,
      }}
    >
        <MintingProgressProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="*" element={<Error />} />
              <Route path="/completion" element={<Completion />} />
              <Route path="/mint" element={<Mint />} />
              <Route path="/test" element={<Test />} />
            </Routes>
          </BrowserRouter>
        </MintingProgressProvider>
    </ThirdwebProvider>
  );
};

export default App;
