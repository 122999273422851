/* eslint-disable no-unused-vars */
import React from 'react';

const IntroMintButton = ({
  eligibility,
  quota,
  address,
  networknNotMatched,
  error,
  handleMint,
}) => {
  const isInEligibleToMint =
    quota?.errors &&
    quota.errors[0].message === 'User is not eligible to mint.' &&
    !networknNotMatched &&
    address;

  const haveQuotas = quota?.quotas;
  const freeMint = quota?.quotas?.free;
  const paidMint = quota?.quotas?.paid;
  const isSubmittedSurvey = quota?.submitted_survey;

  const hasQuotasInEl = eligibility?.quotas;
  const remainingFree = eligibility?.quotas?.remaining?.free || 0;
  const remainingPaid = eligibility?.quotas?.remaining?.paid || 0;
  const totalFree = eligibility?.quotas?.total?.free || 0;
  const totalPaid = eligibility?.quotas?.total?.paid || 0;
  const isFullQuota =
    hasQuotasInEl && remainingFree + remainingPaid !== totalFree + totalPaid;
  // const isFullQuota = false;

  let disabled = false;

  if (address) {
    disabled =
      !quota ||
      quota?.errors ||
      isInEligibleToMint ||
      (address && networknNotMatched) ||
      isFullQuota;
  }

  return (
    <>
      <button
        className="btn btn-md btn-mint"
        onClick={handleMint}
        disabled={disabled}
      >
        Mint
      </button>
      {isInEligibleToMint && (
        <div className="eligible-not errorWrap">
          <p className="text errorLabel">Sorry, you’re not eligible to mint.</p>
        </div>
      )}
      {haveQuotas && !isFullQuota && !isSubmittedSurvey && (
        <p className="eligible-success signup-info">
          Your total eligibility is <br />
          {freeMint} Free Mint and {paidMint} Paid Mint
        </p>
      )}
      {isFullQuota && (
        <p className="signup-info">
          You have completed your mint experience. <br />
          We’re off to the realms!
        </p>
      )}
      {error && (
        <div className="errorWrap">
          <p className="text errorLabel">{error}</p>
        </div>
      )}
    </>
  );
};

export default IntroMintButton;
