import React from 'react';

const Test = () => {
  return (
    <div className="table-container">
      <div className="table-container__header">
        <div className="table-container__header--col-1">
          <span>RANK</span>
        </div>
        <div className="table-container__header--col-2">
          <span>MOCA Name</span>
        </div>
        <div className="table-container__header--col-3">
          <span>Eligibility</span>
        </div>
        <div className="table-container__header--col-4">
          <span>TRIBE</span>
        </div>
        <div className="table-container__header--col-5">
          <span>OWNED BY</span>
        </div>
        <div className="table-container__header--col-6">
          <span>MOCA XP</span>
        </div>
      </div>
      <div className="table-container__item">
        <span className="table-container__item--col-1">1</span>
        <div className="table-container__item--col-2">
          <img src="" alt="" />
          <span>#3990</span>
        </div>
        <span className="table-container__item--col-3">Eligible</span>
        <span className="table-container__item--col-4">Connector</span>
        <span className="table-container__item--col-5">0x5932...627f</span>
        <span className="table-container__item--col-6">9,999,999</span>
      </div>
      <div className="table-container__item">
        <span className="table-container__item--col-1">1</span>
        <div className="table-container__item--col-2">
          <img src="" alt="" />
          <span>#3990</span>
        </div>
        <span className="table-container__item--col-3">Eligible</span>
        <span className="table-container__item--col-4">Connector</span>
        <span className="table-container__item--col-5">0x5932...627f</span>
        <span className="table-container__item--col-6">2,500</span>
      </div>
      <div className="table-container__item">
        <span className="table-container__item--col-1">1</span>
        <div className="table-container__item--col-2">
          <img src="" alt="" />
          <span>#3990</span>
        </div>
        <span className="table-container__item--col-3">Eligible</span>
        <span className="table-container__item--col-4">Connector</span>
        <span className="table-container__item--col-5">0x5932...627f</span>
        <span className="table-container__item--col-6">2,500</span>
      </div>

      <div className="table-container__pagination">
        <span className="table-container__pagination--label">Page</span>
        <select className="table-container__pagination--select">
          <option>1</option>
          <option>2</option>
          <option>3</option>
        </select>
        <span className="table-container__pagination--label">of 800</span>
      </div>
    </div>
  );
};

export default Test;
