import React from 'react';
import WhatIsMocaverseTitle from '../assets/images/what-is-mocaverse.svg';

const WhatIsMocaverse = () => (
  <section
    id="what-is-mocaverse"
    className="what-is-mocaverse-section bg-clouds4"
  >
    <img src={WhatIsMocaverseTitle} alt="" />
    <div className="wim-row">
      <div className="wim-1">
        <div className="content">
          <p>
            <strong>Mocaverse</strong> is the{' '}
            <strong>membership NFT collection</strong> for Animoca Brands’{' '}
            <strong>
              extraordinary family of companies, projects, investments,
              shareholders, and partners.
            </strong>{' '}
            This collection will represent our values surrounding Web3 and
            digital property rights, as well as our one-of-a-kind ecosystem.
          </p>
        </div>
      </div>
      <div className="wim-2">
        <div className="content">
          <p>
            We wish to express our deepest appreciation for the immense support
            of our friends and family by inviting them to this exclusive
            membership experience.{' '}
            <strong>We simply would not be where we are without them.</strong>
          </p>
        </div>
      </div>
      <div className="wim-3">
        <div className="content">
          <p>
            Holders will be able to{' '}
            <strong>Learn, Play, Do Good, and Build together. Utilities</strong>{' '}
            will be co-created by our communities, enjoyed by our communities,
            and powered by Animoca Brands.
          </p>
        </div>
      </div>
      <div className="wim-mobile">
        <div className="content">
          <p>
            <strong>Mocaverse</strong> is the{' '}
            <strong>membership NFT collection</strong> for Animoca Brands’{' '}
            <strong>
              extraordinary family of companies, projects, investments,
              shareholders, and partners.
            </strong>{' '}
            This collection will represent our values surrounding Web3 and
            digital property rights, as well as our one-of-a-kind ecosystem.
          </p>
          <p>
            We wish to express our deepest appreciation for the immense support
            of our friends and family by inviting them to this exclusive
            membership experience.{' '}
            <strong>We simply would not be where we are without them.</strong>
          </p>
          <p>
            Holders will be able to{' '}
            <strong>Learn, Play, Do Good, and Build together. Utilities</strong>{' '}
            will be co-created by our communities, enjoyed by our communities,
            and powered by Animoca Brands.
          </p>
        </div>
      </div>
    </div>
    <a href="/moca" className="btn btn-md mint">
      Mint
    </a>
  </section>
);

export default WhatIsMocaverse;
