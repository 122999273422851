import React from 'react';
import {
  LearnAnimation,
  InvestAnimation,
  PlayAnimation,
  DoGoodAnimation,
  LearnTextAnimation,
  InvestTextAnimation,
  PlayTextAnimation,
  DoGoodTextAnimation,
} from './realms/index';

// const LearnAnimation = React.lazy(() => import('./realms/LearnRealm'));
// const InvestAnimation = React.lazy(() => import('./realms/InvestRealm'));
// const PlayAnimation = React.lazy(() => import('./realms/PlayRealm'));
// const DoGoodAnimation = React.lazy(() => import('./realms/DoGoodRealm'));

// const LearnTextAnimation = React.lazy(() => import('./realms/LearnRealmText'));
// const InvestTextAnimation = React.lazy(() =>
//   import('./realms/InvestRealmText')
// );
// const PlayTextAnimation = React.lazy(() => import('./realms/PlayRealmText'));
// const DoGoodTextAnimation = React.lazy(() =>
//   import('./realms/DoGoodRealmText')
// );

const PlayerRealms = () => (
  <>
    <div className="learn-realm">
      <LearnTextAnimation />
      <LearnAnimation />
    </div>
    <div className="dogood-realm">
      <DoGoodTextAnimation />
      <DoGoodAnimation />
    </div>
    <div className="invest-realm">
      <InvestTextAnimation />
      <InvestAnimation />
    </div>
    <div className="play-realm">
      <PlayTextAnimation />
      <PlayAnimation />
    </div>
  </>
);

export default React.memo(PlayerRealms);
