import React from 'react';
// import { AnimatePresence, motion } from 'framer-motion';

const CompletionWrapper = ({ children }) => (
  <>
    <div className="completion-bg-banner-top" />
    <div className="completion-bg-stars" />
    <div className="completion-bg" />
    <section className="completion">
      {/* <AnimatePresence>
          <motion.div>
            <div className="completion-screen" />
          </motion.div>
        </AnimatePresence> */}
      {children}
      <div className="backtohome">
        <a href="/" className="btn" title="Return Home">
          Return Home
        </a>
      </div>
    </section>
  </>
);

export default CompletionWrapper;
