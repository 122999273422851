import React from 'react';
import ModalWrapper from './common/ModalWrapper';

const ipfsUrl = process.env.REACT_APP_LICENSE_IPNS_URL || '';
const githubUrl = process.env.REACT_APP_LICENSE_READ_URL || '';

const Licence = ({ toggleModalLicence }) => (
  <ModalWrapper
    className="licence-wrappar"
    onClose={() => toggleModalLicence()}
  >
    <h2 className="title">Licence Agreement</h2>
    <p>The NFT Licence Agreement is hosted on {ipfsUrl}.</p>
    <p>
      You may read the contents at this{' '}
      <a href={githubUrl} target="_blank" rel="noreferrer">
        Github Repository.
      </a>
    </p>
  </ModalWrapper>
);

export default Licence;
