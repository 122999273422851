import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setIsPlaying } from '../redux/audio/audioSlice';

export default function Sound() {
  const dispatch = useDispatch();
  const { isPlaying } = useSelector((state) => ({
    isPlaying: state.audio.isPlaying,
  }));
  const audioRef = useRef(null);
  const { pathname } = useLocation();

  useEffect(() => {
    if (isPlaying) {
      audioRef.current?.play();
    } else {
      audioRef.current?.pause();
    }
  }, [audioRef, isPlaying, pathname]);

  return (
    <>
      <button
        className={isPlaying === true ? 'audio is-on' : 'audio is-off'}
        onClick={() => dispatch(setIsPlaying(!isPlaying))}
      />
      <audio src="/sounds/emergence_5.mp3" ref={audioRef} autoPlay loop></audio>
    </>
  );
}
