import React from 'react';
import { useSelector } from 'react-redux';
import homeLogo from '../../assets/images/mocaverse.svg';
import comingSoon from '../../assets/images/comingsoon.svg';

const IntroSection = ({ socials, playerRealms }) => {
  const { quota } = useSelector((state) => ({
    quota: state.data.quota,
  }));
  const isNotInEligibleToMint =
    quota?.errors &&
    quota.errors[0].message === 'User is not eligible to mint.';
  const haveQuotas = quota?.quotas;
  const freeMint = quota?.quotas?.free || 0;
  const paidMint = quota?.quotas?.paid || 0;
  return (
    <section id="home" className="intro orbit-rings comingsoon">
      <div className="intro__wrapper">
        <div className="home-logo-counter-block">
          <div className="home-wrapper">
            <img src={homeLogo} alt="OFF TO THE REALMS" className="home-logo" />
            <img
              src={comingSoon}
              alt="Coming Soon"
              className="comingsoon comingsoon-logo"
            />
            {haveQuotas && (
              <div className="comingsoon-eg-success comingsoon-info">
                Your total eligibility is
                <br /> {freeMint} Free Mint and {paidMint} Paid Mint
              </div>
            )}
            {isNotInEligibleToMint && (
              <div className="comingsoon-info">
                <p className="comingsoon-eg-not errorLabel">
                  Sorry, you’re not eligible to mint.
                </p>
              </div>
            )}
          </div>
          {socials}
        </div>
        {playerRealms}
      </div>
    </section>
  );
};

export default IntroSection;
