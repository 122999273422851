/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  isPlaying: false,
};
const AudioSlice = createSlice({
  name: 'audio',
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsPlaying: (state, action) => {
      state.isPlaying = action.payload;
    },
  },
});

export const { setIsLoading, setIsPlaying } = AudioSlice.actions;
export default AudioSlice.reducer;
