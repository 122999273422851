const apiUrl = process.env.REACT_APP_THRIDWEB_API_URL; 

const callAPI =  async (method, endpoint, body = null) => {
  const options = {
    method,
    headers: { 'Content-type': 'application/json' },
    credentials: 'include',
    ...(method === "POST"
      ? {
          body: JSON.stringify(body),
        }
      : {}),
  };

  try {
    const res = await fetch(
      `${apiUrl}${endpoint}`,
      options
    );
    const el = await res.json();
    return el;
  } catch (e) {
    console.log(e);
    return e;
  }
}

export const getEligibility = () => callAPI('GET','/eligibility');

export const submitLicense = (sig) => callAPI('POST','/license',{ signature: sig, project: 'mocaverse' });

export const postMint = (currency_address, quantity, signature) => callAPI('POST','/mint',{
  currency_address,
  quantity,
  signature,
});

export const fetchSurveyItems =  () => callAPI('GET','/survey');

export const submitSurvey =  (data) => callAPI('POST','/survey', { option_ids: data });

export const checkAuthorization =  () => callAPI('GET','/quotas');

export const getSupplies = async () => callAPI('GET','/supplies');

export const getRealms = async () => callAPI('GET','/realms'); 

export const submitRealms = async (realm_id,token_id ) => callAPI('POST','/realms', {
  "realm_id": realm_id,
  "token_id": token_id,
});

export const submitGettingUpdates = async (address,email ) => {
  const data =  address && email? {
    "address": address,
    "email": email,
  } : {
    "email": email,
  }
  return callAPI('POST','/email', data);
}