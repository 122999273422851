/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAddress, useNetworkMismatch } from '@thirdweb-dev/react';
import { useNavigate } from 'react-router-dom';
import homeLogo from '../../assets/images/mocaverse.svg';
import Modal from '../Modal';
import IntroMintButton from './IntroMintButton';
import { useEffect } from 'react';

const soldOutCondition = +process.env.REACT_APP_UNCLAIMED_SOLD_OUT || 0;

const IntroSection = ({
  note,
  countdown,
  counter,
  socials,
  playerRealms,
  soldOut,
}) => {
  const [showModal, setShowModal] = useState(false);
  const { eligibility, quota, error, supplies } = useSelector((state) => ({
    eligibility: state.data.eligibility,
    quota: state.data.quota,
    error: state.data.error,
    supplies: state.data.supplies,
  }));

  const networknNotMatched = useNetworkMismatch();
  const address = useAddress();
  const navigate = useNavigate();
  const unclaimed = supplies?.unclaimed ? +supplies.unclaimed : '';
  const isSoldOut = unclaimed && +soldOutCondition >= unclaimed;
  const clsSoldOut = isSoldOut ? 'sold-out' : '';
  const [isRenderCounter, setIsRenderCounter] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsRenderCounter(true);
    }, 300);

    return () => clearTimeout(timer);
  }, [isRenderCounter])

  const handleMint = useCallback(() => {
    if (!address) {
      setShowModal(true);
    } else {
      // const link = eligibility && eligibility?.hint ? '/personality' : '/moca';
      const link = quota && quota?.submitted_survey ? '/personality' : '/moca';
      navigate(link);
    }
  }, [eligibility, quota, address]);

  const renderContent = useMemo(() => {
    if (isSoldOut) return <>{soldOut}</>;
    return (
      <>
        {countdown}
        {isRenderCounter && counter}
        <IntroMintButton
          quota={quota}
          eligibility={eligibility}
          address={address}
          networknNotMatched={networknNotMatched}
          error={error}
          handleMint={handleMint}
        />
      </>
    );
  }, [
    isSoldOut,
    quota,
    eligibility,
    address,
    networknNotMatched,
    error,
    handleMint,
    isRenderCounter,
  ]);

  return (
    <>
      {showModal && <Modal toggleModal={() => setShowModal(false)} />}
      <section id="home" className={`intro orbit-rings ${clsSoldOut}`}>
        <div className="intro__wrapper">
          <div className="home-logo-counter-block">
            <div className="home-wrapper">
              <img
                src={homeLogo}
                alt="OFF TO THE REALMS"
                className="home-logo"
              />
              {renderContent}
            </div>
            {socials}
            {note}
          </div>
          {playerRealms}
        </div>
      </section>
    </>
  );
};

export default IntroSection;
